import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import SendbirdChat from '@sendbird/chat';
import {getSettings} from '@/config'
import { GroupChannelModule, GroupChannelListOrder, GroupChannelHandler } from '@sendbird/chat/groupChannel';

const settings = getSettings();
const sendbird = new SendbirdChat.init({appId:settings.sb_key, modules: [new GroupChannelModule(),]});

const _channel_name = (id1, id2) => [id1, id2].sort().join('-')

export const useMessagingStore = defineStore('messaging', () => {
    
    const user = ref(null);
    const channel = ref(null);
    const channels = ref([]);
    const messages = ref([]);
    const unread = computed(() => channels.value.reduce((a,c) => a + c.unreadMessageCount, 0));

    const is_typing = ref(false)

    const add_messages = incoming => messages.value = [...messages.value, ...(Array.isArray(incoming)?incoming:[incoming])].toSorted((a, b) => b.createdAt - a.createdAt);

    sendbird.groupChannel.addGroupChannelHandler('1', new GroupChannelHandler({
        onTypingStatusUpdated: a => is_typing.value = a.isTyping,
        onMessageReceived: (ch, incoming) => {
            add_messages(incoming);
            if(ch.name != channel.value?.name)
                 channels.value = [ch, ...channels.value.filter(c => c.name != ch.name)];
        }
    }));
    
    async function connect(userId) {
        await sendbird.disconnect();
        user.value = await sendbird.connect(String(userId));
    }
    
    async function update() {
        const groupChannelCollection = sendbird.groupChannel.createGroupChannelCollection({
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        });

        while (groupChannelCollection.hasMore) {
            let c = await groupChannelCollection.loadMore();
            channels.value = [...channels.value, ...c];
        }
    }

    const updateUser = async (nickname, profileImageUrl) => await sendbird.updateCurrentUserInfo({nickname, profileImageUrl});
    
    async function createChannel(id1, id2, channel_name) {
        await sendbird.setChannelInvitationPreference(true);
        return await sendbird.groupChannel.createChannel({
            name: channel_name,
            invitedUserIds: [id1, id2],
            operatorUserIds: [id1, id2],
            isDistinct: true,
        });
    }

    async function findChannel(channel_name) {
        const params = {includeEmpty: true, channelNameContainsFilter: channel_name,};
        const query = sendbird.groupChannel.createMyGroupChannelListQuery(params);
        const channels = await query.next()
        if(channels.length > 0) return channels[0];
        return null;
    }

    async function getChannel(user1, user2) {
        const [id1, id2] = [String(user1.id), String(user2.id)];
        try {
            const channel_name = _channel_name(id1, id2);
            var channel = await findChannel(channel_name);
            if(channel) return channel;
            return await createChannel(id1, id2, channel_name);
        } catch (error) {
            console.error('Error creating or opening channel:', error);
            throw error;
        }
    }

    let messageCollection = null;

    async function open(channel_url, onMessage) {
        
        channel.value = await sendbird.groupChannel.getChannel(channel_url);
        if(messageCollection) messageCollection.setMessageCollectionHandler(null);
        
        messageCollection = channel.value.createMessageCollection({
            startingPoint: Date.now(),
            limit: 50
        });
                        
        messageCollection.setMessageCollectionHandler({
            onMessagesAdded: async (context, channel, incoming) => {
                add_messages(incoming);
                //console.log(incoming)
                //debugger;
                await channel.markAsRead();
                if (onMessage) onMessage(incoming);
              },
            });

        add_messages(await messageCollection.loadPrevious());
    }

    async function close() {
        if(messageCollection) messageCollection.setMessageCollectionHandler(null);
        if(channel.value != null) channel.value = null;
        messages.value = [];
    }

    async function loadMore() {
        if (messageCollection?.hasPrevious)
            add_messages(await messageCollection.loadPrevious());
    }

    async function send(message) {
        if (channel.value && message.length > 0) {
            const params = { message };
            await channel.value.sendUserMessage(params)
        }
    }

    const is_connected = computed(() => !!channel.value);

    return { channels, channel, messages, unread, is_typing, is_connected, connect, update, updateUser, getChannel, open, close, send, loadMore };
});
